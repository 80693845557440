import React, { Component } from 'react';
import I18n from 'i18n-js';
import { Form, Button, ListGroup, Badge } from 'react-bootstrap';
import AvainiaCore from 'avainia-core-api';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import { Trash, Edit, Link, File, FolderPlus } from '../Icon/Icon.js';
import Error from '../Error/Error.js';
import Loading from '../Loading/Loading.js';
import AvainiaPanel from './AvainiaPanel.js';
import EditMaterialformModal from '../Modals/EditMaterialformModal.js';
import LinkMaterialformModal from '../Modals/LinkMaterialformModal.js';
import LocalStorageService from '../../../AvainiaTools/LocalStorageService.js';
import AvainiaTable from '../AvainiaTable/AvainiaTable.js';

const Modals = {
  materialFormEdit: 1,
  materialFormLink: 2,
};

class MaterialformAdminPanel extends Component {
  constructor(props) {
    super(props);

    this.state = {
      name: '',
      parent_form: null,
      modal: false,
      materialformToEdit: false,
      materialformToLink: false,
      materialforms: [],
      modalKeyHack: 1,
      deadline: null,
      loading: false,
      error: false,
    };
  }

  componentDidMount() {
    const api = new AvainiaCore(LocalStorageService.getToken);

    api.materialFormsGet().then((materialforms) => {

      let materialforms_sorted = [];

      materialforms.map((mf) => {
        let index = materialforms_sorted.findIndex(x => x.name == mf.project_name);

        if(index == -1){
          materialforms_sorted.push({
            name: mf.project_name,
            materialforms: []
          });
          materialforms_sorted[materialforms_sorted.length - 1].materialforms.push(mf);
        } else {
          materialforms_sorted[index].materialforms.push(mf); 
        }
      });

      this.setState({materialforms: materialforms_sorted});
    });
  }

  delete = (materialform) => {
    if (this.state.loading) { return; }
    if (!window.confirm(I18n.t('views.materialforms.confirm-delete'))) { return; } // TODO: Improve

    this.setState({ loading: true, error: false }, () => {
      const api = new AvainiaCore(LocalStorageService.getToken);
      api.projectMaterialFormsDelete(this.props.project.id, materialform.id).then((result) => {
        if (result.error) { return this.setState({ loading: false, error: result.error }); }

        window.location.reload(); // TODO: Improve
      });
    });
  }

  edit = (materialform) => {
    this.setState({ materialformToEdit: materialform, modal: Modals.materialFormEdit });
  }

  showLinkingModal = (materialformToLink) => {
    this.setState({ materialformToLink, modal: Modals.materialFormLink });
  }

  // TODO: Improve performance - replace this by mapping the materialforms to apartment templates directly during the view initialisation phase
  getApartmentTemplatesLinkedToMaterialform = (targetMaterialform) => {
    const found = [];
    this.props.apartmentTemplates.forEach((tmpl) => {
      if (!tmpl.materialforms) { return; }
      tmpl.materialforms.forEach((materialform) => {
        // eslint-disable-next-line eqeqeq
        if (materialform.id == targetMaterialform.id) {
          found.push(materialform);
        }
      });
    });
    return found.length === 0 ? false : found; // Oh yeaaah, who needs stable types?
  }

  refreshModal = () => {
    this.setState({ modalKeyHack: this.state.modalKeyHack + 1 });
  }

  createMaterialForm = (e) => {
    if (this.state.loading) { return; }
    const formattedDate = this.state.deadline ? new Date(this.state.deadline.getTime() - (this.state.deadline.getTimezoneOffset() * 60000)) : '';

    this.setState({ loading: true, error: false }, () => {
      const payload = {
        name: this.state.name,
        deadline: formattedDate,
        parent_form: this.state.parent_form,
        is_due: !!this.state.deadline,
      };
      const api = new AvainiaCore(LocalStorageService.getToken);

      api.projectMaterialFormsCreate(this.props.project.id, payload).then((materialform) => {
        if (materialform.error) { return this.setState({ error: materialform.error, loading: false, deadline: null, name: '' }); }

        window.location.reload(); // TODO: Improve
      });
    });
  }

  copyMaterialForm = (row) => {
    const api = new AvainiaCore(LocalStorageService.getToken);
    api.projectMaterialFormsDuplicate(row.project_id,row.id).then((materialform) => {
      window.location.reload();
    });
  }

  hideModal = (e) => { this.setState({ modal: false }); }

  onChange = (e) => { this.setState({ [e.target.name]: e.target.value }); }

  handleDateChange = (date) => {
    this.setState({
      deadline: date,
    });
  };

  render() {
    if (this.state.error) { return <Error error={this.state.error} />; }
    if (this.state.loading) { return <Loading />; }
    return <AvainiaPanel double={this.props.double} icon={<File />} heading={I18n.t('views.materialforms.materialforms')}>
        <AvainiaTable data={this.props.materialforms} keyField="id" columns={[
          { dataField: 'id', text: I18n.t('general.id'), headerStyle: { width: '60px' }},
          { dataField: 'name', text: I18n.t('general.name'), headerStyle: { width: '400px' }},
          { dataField: 'status',
            text: I18n.t('views.materialforms.status'),
            formatter: (cell, row) => (this.getApartmentTemplatesLinkedToMaterialform(row)
              ? <Badge variant="success">
                    {I18n.t('general.active')}
                  </Badge>
              : <Badge variant="warning">
                    {I18n.t('general.inactive')}
                  </Badge>),
          },
          { dataField: 'deadline',
            text: I18n.t('views.materialforms.options'),
            formatter: (cell, row) => (!this.getApartmentTemplatesLinkedToMaterialform(row)
              ?
                  <>
                    <Trash onClick={() => { this.delete(row); }} className="clickable" />
                    <Edit onClick={() => { this.edit(row); }} className="clickable" />
                    <Link onClick={() => { this.showLinkingModal(row); }} className="clickable" />
                    <FolderPlus onClick={() => { this.copyMaterialForm(row); }} className="clickable" />
                  </>
              : <>
                  <Link onClick={() => { this.showLinkingModal(row); }} className="clickable" />
                  <FolderPlus onClick={() => { this.copyMaterialForm(row); }} className="clickable" />
                </>
            ),
          },
        ]} />

      <h3>{I18n.t('views.materialforms.create-new-materialform')}</h3>

      <Form.Group controlId="materialform-name">
        <Form.Label>{I18n.t('views.materialforms.materialform-name')}</Form.Label>
        <Form.Control type="text" onChange={this.onChange} name="name" value={this.state.name} />
      </Form.Group>

      <Form.Group controlId="materialform-original-label">
        <Form.Label>{I18n.t('views.materialforms.materialform-original-label')}</Form.Label>        
        <Form.Control as="select" name="parent_form" onChange={this.onChange}>
          <option key={0} value={null}>{I18n.t('views.materialforms.materialform-original-default')}</option>
          {this.state.materialforms && this.state.materialforms.map((mf, key) => {
            return(
              <optgroup key={"opt-"+key} label={mf.name}>
                {mf.materialforms.map((form) => <option key={form.id} value={form.id}>{form.name}</option>)}
              </optgroup>
            );
          })}
        </Form.Control>
      </Form.Group>

      {!this.state.loading &&
        <Button variant="primary" onClick={this.createMaterialForm}>{I18n.t('views.materialforms.button-create')}</Button>
      }

      {this.state.modal === Modals.materialFormEdit &&
        <EditMaterialformModal
          key={this.state.modalKeyHack}
          onHide={this.hideModal}
          materialform={this.state.materialformToEdit}
          project={this.props.project}
          refreshModal={this.refreshModal}
        />
      }

      {this.state.modal === Modals.materialFormLink &&
        <LinkMaterialformModal
          key={this.state.modalKeyHack}
          onHide={this.hideModal}
          materialform={this.state.materialformToLink}
          apartmentTemplates={this.props.apartmentTemplates}
          linked={this.getApartmentTemplatesLinkedToMaterialform(this.state.materialformToLink)}
          project={this.props.project}
        />
      }

    </AvainiaPanel>;
  }
}

export default MaterialformAdminPanel;
